import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Section from "./Section";
import Features from "./Features";

const styles = {};

class App extends Component {
  render() {
    return (
      <div style={{ marginBottom: 100 }}>
        <Section />
        <Features />
      </div>
    );
  }
}

export default withStyles(styles)(App);
