import React from 'react'
import { Paper, Divider } from '@material-ui/core';
const images = require.context('./assets', true);

class Insights extends React.Component {
    render() {
        return (
            <div className="container" style={{ marginTop: 50, marginBottom: 150 }}>
                <h3 style={{ textAlign: "center" }}>Uncover Insights</h3>
                <h5 style={{ textAlign: "center" }}>Discover insights about your company by uncovering skill gaps, identifying trends and managing your talent pipeline. Understand how relevant your organization's talent practices are and how your existing talent can be utilized and developed to deliver greater value to your customers better than your competitors</h5>

                <div className="row" style={{ marginTop: 100, marginBottom: 30 }}>
                    <div className="col-12">
                        <h3>Skill Gap Analysis</h3>
                        <div style={{ marginBottom: 30 }}>
                            Always be future ready by identifying and closing the gaps in talent in your organization
                        </div>
                    </div>
                    <div className="col-12 col-md-8">
                        <Paper style={{ padding: 20 }}>
                            <img alt="Optimus Screenshot" className="img img-fluid" style={{ height: 250 }} src={images("./i2.png")} />
                        </Paper>
                    </div>
                    <div className="col-12 col-md-4">
                        <Paper style={{ padding: 20 }}>
                            <img alt="Optimus Screenshot" className="img img-fluid" style={{ height: 250 }} src={images("./i3.png")} />
                        </Paper>
                    </div>
                </div>
                <Divider />

                <div className="row" style={{ marginTop: 30, marginBottom: 30 }}>
                    <div className="col-12 col-md-6">
                        <Paper style={{ padding: 20 }}>
                            <img alt="Optimus Screenshot" className="img img-fluid" src={images("./i5.png")} />
                        </Paper>
                    </div>
                    <div className="col-12 col-md-6">
                        <h3>Skill Trends</h3>
                        <div>
                            Get a pulse of your organization to see what employees are interested in learning and the skills required for critical, high impact projects
                        </div>
                    </div>
                </div>
                <Divider />

                <div className="row" style={{ marginTop: 30, marginBottom: 30 }}>
                    <div className="col-12 col-md-6">
                        <h3>Efficient Staffing</h3>
                        <div>
                            Reduce costs by upskilling and cost skilling employees internally to reduce salary costs and dependency on external hires
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <Paper style={{ padding: 20 }}>
                            <img alt="Optimus Screenshot" className="img img-fluid" src={images("./i4.png")} />
                        </Paper>
                    </div>
                </div>
                <Divider />

                <div className="row" style={{ marginTop: 30, marginBottom: 30 }}>
                    <div className="col-12 col-md-6">
                        <Paper style={{ padding: 20 }}>
                            <img alt="Optimus Screenshot" className="img img-fluid" src={images("./i1.png")} />
                        </Paper>
                    </div>
                    <div className="col-12 col-md-6">
                        <h3>Identify top talent</h3>
                        <div>
                            Find the experts in your organization and place them in roles they'll thrive in. Recognize their mastery by making them subject matter experts and mentors
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Insights;