import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreVert';
//eslint-disable-next-line
import { BrowserRouter as Router, Link } from "react-router-dom";
import { withRouter } from 'react-router-dom'

const images = require.context('./assets', true);

const styles = theme => ({
    root: {
        width: '100%',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
});

class Header extends React.Component {
    state = {
        anchorEl: null,
        mobileMoreAnchorEl: null,
    };

    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = (linkTo) => {
        this.props.history.push(linkTo)
        this.setState({ mobileMoreAnchorEl: null });
    };

    render() {
        const { anchorEl, mobileMoreAnchorEl } = this.state;
        const { classes } = this.props;
        const isMenuOpen = Boolean(anchorEl);
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
                <MenuItem onClick={this.handleMenuClose}>My account</MenuItem>
            </Menu>
        );

        const renderMobileMenu = (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={this.handleMenuClose}
            >
                <Link to="/perform" style={{ textDecoration: 'none' }}>
                    <MenuItem onClick={() => this.handleMobileMenuClose('/')}>
                        <Typography variant="h6" color="inherit" noWrap>
                            Perform
                    </Typography>
                    </MenuItem>
                </Link>
                <Link to="/engage" style={{ textDecoration: 'none' }}>
                    <MenuItem onClick={() => this.handleMobileMenuClose('/products')}>
                        <Typography variant="h6" color="inherit" noWrap>
                            Engage
                    </Typography>
                    </MenuItem>
                </Link>
                <Link to="/insights" style={{ textDecoration: 'none' }}>
                    <MenuItem onClick={() => this.handleMobileMenuClose('/products')}>
                        <Typography variant="h6" color="inherit" noWrap>
                            Insights
                    </Typography>
                    </MenuItem>
                </Link>
            </Menu>
        );

        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" color="inherit" noWrap>
                            <Link to="/" style={{ textDecoration: 'none', color: "white" }}>
                                <img alt="Optimus Screenshot" className="img img-fluid" style={{ height: 50, marginRight: 10 }} src={images("./logo50.png")} />
                                Optimus Spark
                            </Link>
                        </Typography>
                        <div className={classes.grow} />
                        <div className={classes.sectionDesktop}>
                            <div style={{ paddingRight: 20 }} color="inherit" className={classes.grow}>
                                <Link to="/perform" style={{ textDecoration: 'none', color: "white" }}>
                                    Perform
                                </Link>
                            </div>
                            <div style={{ paddingRight: 20 }} color="inherit" className={classes.grow}>
                                <Link to="/engage" style={{ textDecoration: 'none', color: "white" }}>
                                    Engage
                                </Link>
                            </div>
                            <div color="inherit" className={classes.grow}>
                                <Link to="/insights" style={{ textDecoration: 'none', color: "white" }}>
                                    Insights
                                </Link>
                            </div>
                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                                <MoreIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                {renderMenu}
                {renderMobileMenu}
            </div>
        );
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Header));