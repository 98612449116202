import React from 'react'
import { Paper, Divider } from '@material-ui/core';
const images = require.context('./assets', true);

class Performance extends React.Component {
    render() {
        return (
            <div className="container" style={{ marginTop: 50, marginBottom: 150 }}>
                <h3 style={{ textAlign: "center" }}>Unleash Performance</h3>
                <h5 style={{ textAlign: "center" }}>Understand key performance drivers and train your team to reach your goals quicker, cheaper and better than you ever could before</h5>

                <div className="row" style={{ marginTop: 100, marginBottom: 25 }}>
                    <div className="col-12 col-md-6">
                        <h3 style={{ marginTop: 30 }}>See Progress</h3>
                        <div>
                            See progress on your team's goals. We also integrate with your favorite tools such as Asana and JIRA.
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <Paper style={{ padding: 20 }}>
                            <img alt="Optimus Screenshot" className="img img-fluid" src={images("./p1.png")} />
                        </Paper>
                    </div>
                </div>
                <Divider />

                <div className="row" style={{ marginTop: 25, marginBottom: 30 }}>
                    <div className="col-12">
                        <h3>Team Analytics</h3>
                        <div style={{ marginBottom: 30 }}>
                            Understand your team's skills and see the impact training has on their performance
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <Paper style={{ padding: 20 }}>
                            <img alt="Optimus Screenshot" className="img img-fluid" style={{ height: 350 }} src={images("./p2.png")} />
                        </Paper>
                    </div>
                    <div className="col-12 col-md-6">
                        <Paper style={{ padding: 20 }}>
                            <img alt="Optimus Screenshot" className="img img-fluid" style={{ height: 350 }} src={images("./p3.png")} />
                        </Paper>
                    </div>
                </div>
                <Divider />

                <div className="row" style={{ marginTop: 30, marginBottom: 30 }}>
                    <div className="col-12 col-md-6">
                        <Paper style={{ padding: 20 }}>
                            <img alt="Optimus Screenshot" className="img img-fluid" src={images("./p4.png")} />
                        </Paper>
                    </div>
                    <div className="col-12 col-md-6">
                        <h3>Insights</h3>
                        <div>
                            Discover the true drivers of performance and take action instantly
                        </div>
                    </div>
                </div>
                <Divider />

                <div className="row" style={{ marginTop: 25, marginBottom: 30 }}>
                    <div className="col-12">
                        <h3>Staffing</h3>
                        <div style={{ marginBottom: 30 }}>
                            Find the best employees in your company based on your requirements. Specify the exact skills you require and view historical feedback to hire your next team member
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <Paper style={{ padding: 20 }}>
                            <img alt="Optimus Screenshot" className="img img-fluid" style={{ height: 400 }} src={images("./p5.png")} />
                        </Paper>
                    </div>
                    <div className="col-12 col-md-6">
                        <Paper style={{ padding: 20 }}>
                            <img alt="Optimus Screenshot" className="img img-fluid" style={{ height: 400 }} src={images("./p6.png")} />
                        </Paper>
                    </div>
                </div>

                <Divider />
                <div className="row" style={{ marginTop: 30, marginBottom: 30 }}>
                    <div className="col-12 col-md-6">
                        <Paper style={{ padding: 20 }}>
                            <img alt="Optimus Screenshot" className="img img-fluid" src={images("./p7.png")} />
                        </Paper>
                    </div>
                    <div className="col-12 col-md-6">
                        <h3>Team Snapshot</h3>
                        <div>
                            Understand your team members, their skills and aspirations at a deeper level
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}

export default Performance;