import React from 'react'
import { Paper, Divider } from '@material-ui/core';
const images = require.context('./assets', true);

class Engage extends React.Component {
    render() {
        return (
            <div className="container" style={{ marginTop: 50, marginBottom: 150 }}>
                <h3 style={{ textAlign: "center" }}>Engage Employees</h3>
                <h5 style={{ textAlign: "center" }}>Engage and retain your employees at each step by helping them learn, grow and develop through personalized recommendations and content based on their skills and aspirations</h5>

                <div className="row" style={{ marginTop: 100, marginBottom: 30 }}>
                    <div className="col-12">
                        <h3>Learning and Development</h3>
                        <div style={{ marginBottom: 30 }}>
                            Show personalized learning and development content to create a culture of constant improvement among your employees. Leverage peer to peer sessions, micro lessons and targeted content to maximize effectiveness
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <Paper style={{ padding: 20 }}>
                            <img alt="Optimus Screenshot" className="img img-fluid" style={{ height: 300 }} src={images("./e4.png")} />
                        </Paper>
                    </div>
                    <div className="col-12 col-md-6">
                        <Paper style={{ padding: 20 }}>
                            <img alt="Optimus Screenshot" className="img img-fluid" style={{ height: 300 }} src={images("./e6.png")} />
                        </Paper>
                    </div>
                </div>
                <Divider />

                <div className="row" style={{ marginTop: 30, marginBottom: 30 }}>
                    <div className="col-12 col-md-6">
                        <Paper style={{ padding: 20 }}>
                            <img alt="Optimus Screenshot" className="img img-fluid" src={images("./a2.png")} />
                        </Paper>
                    </div>
                    <div className="col-12 col-md-6">
                        <h3>Maximize Employee Productivity</h3>
                        <div>
                            Help employees be more productive by automatically offering them timely coaching and guidance
                        </div>
                    </div>
                </div>
                <Divider />

                <div className="row" style={{ marginTop: 30, marginBottom: 30 }}>
                    <div className="col-12">
                        <h3>Transparent Growth</h3>
                        <div style={{ marginBottom: 30 }}>
                            Create automated personalized growth and development plans for each employee to keep them motivated, engaged and on track for their next big promotion
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <Paper style={{ padding: 20 }}>
                            <img alt="Optimus Screenshot" className="img img-fluid" style={{ height: 350 }} src={images("./e1.png")} />
                        </Paper>
                    </div>
                    <div className="col-12 col-md-6">
                        <Paper style={{ padding: 20 }}>
                            <img alt="Optimus Screenshot" className="img img-fluid" style={{ height: 350 }} src={images("./e5.png")} />
                        </Paper>
                    </div>
                </div>
                <Divider />

                <div className="row" style={{ marginTop: 30, marginBottom: 30 }}>
                    <div className="col-12 col-md-6">
                        <Paper style={{ padding: 20 }}>
                            <img alt="Optimus Screenshot" className="img img-fluid" src={images("./e3.png")} />
                        </Paper>
                    </div>
                    <div className="col-12 col-md-6">
                        <h3>Internal Mobility</h3>
                        <div>
                            Keep your employees engaged by automatically showing them projects they match their interests and skills 
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Engage;