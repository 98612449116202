import React from "react";
import { Paper, Divider, Button } from "@material-ui/core";
//eslint-disable-next-line
import { BrowserRouter as Router, Link } from "react-router-dom";

const images = require.context("./assets", true);

class Features extends React.Component {
  render() {
    return (
      <div className="container" style={{ marginTop: 50 }} id="feature-section">
        <h2 style={{ textAlign: "center", marginBottom: 50 }}>What we do</h2>
        <div className="row" style={{ marginBottom: 25 }}>
          <div className="col-12 col-md-6">
            <h3 style={{ marginTop: 30 }}>Unleash Performance</h3>
            <div>
              Understand key performance drivers and train your team to reach
              your goals quicker, cheaper and better than you ever could before
            </div>
            <Link
              to="/perform"
              style={{ textDecoration: "none", color: "white" }}
            >
              <Button
                color="primary"
                style={{ marginTop: 10, marginLeft: -10 }}
              >
                View Features
              </Button>
            </Link>
          </div>
          <div className="col-12 col-md-6">
            <Paper style={{ padding: 20 }}>
              <img
                alt="Optimus Screenshot"
                className="img img-fluid"
                src={images("./a4.png")}
              />
            </Paper>
          </div>
        </div>
        <Divider />
        <div className="row" style={{ marginTop: 25, marginBottom: 25 }}>
          <div className="col-12 col-md-6">
            <Paper style={{ padding: 20 }}>
              <img
                alt="Optimus Screenshot"
                style={{ height: 300 }}
                className="img img-fluid"
                src={images("./a5.png")}
              />
            </Paper>
          </div>
          <div className="col-12 col-md-6">
            <h3 style={{ marginTop: 30 }}>Engage Employees</h3>
            <div>
              Engage and retain your employees at each step by helping them
              learn, grow and develop through personalized recommendations and
              content
            </div>
            <Link
              to="/engage"
              style={{ textDecoration: "none", color: "white" }}
            >
              <Button
                color="primary"
                style={{ marginTop: 10, marginLeft: -10 }}
              >
                View Features
              </Button>
            </Link>
          </div>
        </div>
        <Divider />
        <div className="row" style={{ marginTop: 25 }}>
          <div className="col-12 col-md-6">
            <h3 style={{ marginTop: 30 }}>Uncover Insights</h3>
            <div>
              Discover insights about your company by uncovering skill gaps,
              identifying trends and managing your talent pipeline
            </div>
            <Link
              to="/insights"
              style={{ textDecoration: "none", color: "white" }}
            >
              <Button
                color="primary"
                style={{ marginTop: 10, marginLeft: -10 }}
              >
                View Features
              </Button>
            </Link>
          </div>
          <div className="col-12 col-md-6">
            <Paper style={{ padding: 20 }}>
              <img
                alt="Optimus Screenshot"
                style={{ height: 300 }}
                className="img img-fluid"
                src={images("./a1.png")}
              />
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

export default Features;
