import { BrowserRouter as Router, Route } from "react-router-dom";
import App from './App';
import React from 'react'
import Header from './Header';
import Engage from "./Engage";
import Insights from "./Insights";
import Performance from "./Performance";

function AppRouter({ empid }) {
    return (
        <Router>
            <div>
                <Header />
                <Route path="/" exact render={() => <App />} />
                <Route path="/perform" exact render={() => <Performance />} />
                <Route path="/engage" exact render={() => <Engage />} />
                <Route path="/insights" exact render={() => <Insights />} />
            </div>
        </Router>
    );
}

export default AppRouter;