import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Paper, Divider } from "@material-ui/core";
import { HashLink as Link } from "react-router-hash-link";

const images = require.context("./assets", true);

const styles = theme => ({
  padding: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20
    },
    [theme.breakpoints.up("md")]: {
      padding: 100
    }
  }
});
class Section extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className={classes.padding}>
                <h1>Performance: Reimagined</h1>
                <h5 style={{ marginTop: 50 }}>
                  We help you deliver better customer value by maximizing the
                  potential of your employees
                </h5>
                <Link
                  smooth
                  to="#feature-section"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    style={{ marginTop: 50, textDecoration: "none" }}
                    variant="outlined"
                    size="large"
                    color="secondary"
                  >
                    Find out more
                  </Button>
                </Link>
              </div>
            </div>
            <div className="col" style={{ margin: 20 }}>
              <Paper style={{ padding: 20, marginTop: 50 }}>
                <div style={{ textAlign: "center" }}>
                  <img
                    alt="Optimus Screenshot"
                    className="img img-fluid"
                    src={images("./a2.png")}
                  />
                </div>
              </Paper>
            </div>
          </div>
          <div style={{ marginTop: 30 }}>
            <Divider />
          </div>
        </div>
        <div className="container">
          <p style={{ fontSize: 18, fontStyle: "italic", marginTop: 30 }}>
            At Optimus Spark, we help you beat your competition by uncovering
            ways to improve your team's performance by understanding your
            employee's skills, offering relevant and timely coaching and
            empowering your team to do their very best each step of the way
          </p>
          <div style={{ marginTop: 30 }}>
            <Divider />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Section);
