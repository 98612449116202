import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { pink, indigo } from '@material-ui/core/colors'
import AppRouter from './AppRouter';

const theme = createMuiTheme({
    palette: {
        secondary: {
            main: pink[700]
        },
        primary: {
            main: indigo[700]
        }
    },
    typography: {
        useNextVariants: true,
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '"Lato"',
            'sans-serif'
        ].join(',')
    }
});


ReactDOM.render(
    <AppRouter>
        <MuiThemeProvider theme={theme}>
            <App />
        </MuiThemeProvider>
    </AppRouter>,
    document.getElementById('root'));

